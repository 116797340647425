<template>
  <div v-if="shouldShowComponent" class="information-table">
    <table>
      <tbody>
        <tr>
          <td>
            <img
              src="https://assets.parkos.com/assets/img/airport/serp/car.svg"
              alt=""
              width="24"
              height="24"
              class="mr-2.5"
            />
            {{ $i18n('location.serp.total_offers') }}
          </td>
          <td>
            <strong>{{ merchantCount }}</strong>
          </td>
        </tr>
        <tr v-if="topRatedParking" id="locations_information_best_rated_link">
          <td>
            <img
              src="https://assets.parkos.com/assets/img/airport/serp/message.svg"
              alt=""
              width="24"
              height="24"
              class="mr-2.5"
            />
            {{ $i18n('location.serp.best_rated_offer') }}
          </td>
          <td>
            <a :href="topRatedParking.url">{{ topRatedParking.name }}</a>
          </td>
        </tr>
        <tr v-if="cheapestParking" id="locations_information_cheapest_link">
          <td>
            <img
              src="https://assets.parkos.com/assets/img/airport/serp/done.svg"
              alt=""
              width="24"
              height="24"
              class="mr-2.5"
            />
            {{ $i18n('location.serp.cheapest_offer') }}
          </td>
          <td>
            <a :href="cheapestParking.url">{{ cheapestParking.name }}</a>
          </td>
        </tr>
        <tr v-if="closestParking" id="locations_information_closest_link">
          <td>
            <img
              src="https://assets.parkos.com/assets/img/airport/serp/location.svg"
              alt=""
              width="24"
              height="24"
              class="mr-2.5"
            />
            {{ $i18n('location.serp.closest_offer') }}
          </td>
          <td>
            <a :href="closestParking.url">{{ closestParking.name }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  computed: {
    airportData() {
      return this.$store.state.airportData;
    },
    airportSlug() {
      return this.$store.state.airport.slug;
    },
    baseURL() {
      const { urlEnvPrefix, host } = this.$paths;

      return `https://${urlEnvPrefix}${host}`;
    },
    extendedAirportData() {
      return this.airportData.airportExtendedData;
    },
    topRatedParking() {
      return this.formatMerchant(
        this.extendedAirportData['best-rated']?.merchant
      );
    },
    cheapestParking() {
      return this.formatMerchant(this.extendedAirportData.cheapest?.merchant);
    },
    closestParking() {
      return this.formatMerchant(this.extendedAirportData.closest?.merchant);
    },
    merchantCount() {
      return this.extendedAirportData['merchant-count'] || 0;
    },
    shouldShowComponent() {
      return (
        this.airportData &&
        this.extendedAirportData &&
        (this.merchantCount ||
          this.closestParking ||
          this.cheapestParking ||
          this.topRatedParking)
      );
    },
  },
  methods: {
    formatMerchant(merchant) {
      if (!merchant) return null;

      return {
        ...merchant,
        url: new URL(
          `${this.airportSlug}/${merchant.slug}.html`,
          this.baseURL
        ).toString(),
      };
    },
  },
};
</script>

<style lang="scss">
.information-table {
  @apply text-md md:text-base;

  table,
  tr,
  td {
    @apply flex;
  }

  table {
    @apply md:border md:rounded md:border-athens;
  }

  tbody {
    @apply w-full items-start;
    @apply md:flex md:justify-stretch md:divide-x;
  }

  tr {
    @apply justify-between items-start;
    @apply mb-2.5 md:flex-col md:basis-1/4 md:p-4 md:hover:bg-[#F9FBFE] md:mb-0 md:h-full md:justify-start;
  }

  td {
    @apply items-center;

    &:last-child {
      @apply text-right md:text-left;
    }
  }
}
</style>
